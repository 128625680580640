.mainLogo{
    /*width: 250px;*/
    /*height: 50px;*/
}

.sider{
    overflow: auto;
    height: 85vh;
    position: fixed;
    insetInlineStart: 0;
    top: 10px;
    bottom: 0;
    scrollbarWidth: 'thin';
    scrollbarColor: 'unset';
    /*width: 0;*/
    max-width: 300px;
}

.collapseButton{
    position: absolute;
    left: 220px;
    z-index: 20;
    overflow: visible;
}