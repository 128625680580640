
/*.active{*/
/*    color: #090;*/
/*}*/

/*.active:hover{*/
/*    color: #0c0;*/
/*}*/


.night{
    cursor: pointer;
    background-color: #1a1325;
    color: #ffffff;
    padding: 3px 5px;
    border-radius: 15%;
    box-shadow: 0px 0px 7px 10px #3e206964;
}
.day{
    cursor: pointer;
    background-color: #ffffff;
    color: #000;
    padding: 3px 5px;
    border-radius: 15%;
    box-shadow: 0px 0px 7px 10px rgb(255 255 255 / 69%);
}

.themeSwitcher{
    position: fixed;
    z-index: 100;
    top: 10px;
    right: 100px;
}