.errors{
    color: #900;
}

.title{
    display: flex;
    flex: auto;
    align-items: center;
    justify-content: space-between;
}

.search_icon{
    cursor: pointer;
}

.table{
    scrollbar-width: thin;
    scrollbar-color: #eaeaea transparent;
    scrollbar-gutter: stable;
}

.add{
    position: absolute;
    top: 10vh;
    right: 2vw;
}

.sorting_wrapper{
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.active{
    color: #381e5c;
}

.tableTitle{
    display: flex;
    flex-direction: row;
}

.flex{
    max-width: 400px;
    width: 400px;
    border: 1px #888 solid;
    padding: 5px;
    border-radius: 5px;
}
.main_info{
    max-width: 350px;
    width: 350px;
    border: 1px #888 solid;
    padding: 5px;
    border-radius: 5px;
}

.statusSelect{
    min-width: 200px;
    border: 1px #888888 solid;
    border-radius: 5px;
}
.copyEmail{
    display: none;
    cursor: pointer;
    position: absolute;
    right: 0px;
}
.emailDiv{
    position: relative;
}
.emailDiv:hover .copyEmail{
    display: inline-block;
}

.copyPhone{
    display: none;
    cursor: pointer;
    position: absolute;
    right: 0px;
}
.phoneDiv{
    position: relative;
}
.phoneDiv:hover .copyPhone{
    display: inline-block;
}

.avatar{
    /*float: left;*/
    width: 60px;
    margin: auto;
}

.avatarDiv{
    text-align: center;
}

.clientDetails,.details{
    padding: 0 0 0 10px;
}