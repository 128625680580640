.errors{
    color: #900;
}

.title{
    display: flex;
    flex: auto;
    align-items: center;
    justify-content: space-between;
}

.search_icon{
    cursor: pointer;
}

.table{
    scrollbar-width: thin;
    scrollbar-color: #eaeaea transparent;
    scrollbar-gutter: stable;
}

.add{
    position: absolute;
    top: 10vh;
    right: 2vw;
}