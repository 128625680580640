

.link{
    cursor: pointer;
    color: #7445af;
}


.add{
    position: absolute;
    top: 10vh;
    right: 2vw;
}