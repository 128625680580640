.languages span{
    cursor: pointer;
}

.languages{
    position: fixed;
    z-index: 100;
    top: 10px;
    right: 240px;
}

.active{
    color: #642ab5;
}

.active:hover{
    color: #854eca;
}

.exit{
    position: fixed;
    z-index: 100;
    top: 5px;
    right: 10px;
}

.en{

}

.ru{

}